document.addEventListener("DOMContentLoaded", () => {
    const menuItems = document.querySelectorAll('nav a[href^="#"]');
    const sections = document.querySelectorAll('section');
    const scrollButtons = document.querySelectorAll('.scroll-to-pricing');
    const fixedMenuHeight = 70; // Może być konieczne dostosowanie do rzeczywistej wysokości menu

    const smoothScroll = (href) => {
        const targetElement = document.querySelector(href);
        if (targetElement) {
            const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - fixedMenuHeight;
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
            // Dodaj opóźnienie przed ponownym sprawdzeniem sekcji
            setTimeout(checkSectionInView, 600); // Opóźnienie może wymagać dostosowania
        }
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        let href = e.currentTarget.getAttribute('href');
        smoothScroll(href);
    };

    menuItems.forEach(item => {
        item.addEventListener('click', handleLinkClick);
    });

    scrollButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            smoothScroll('#pricing');
        });
    });

    const checkSectionInView = () => {
        let scrollPosition = window.scrollY + fixedMenuHeight;
        let buffer = 5; // Bufor w pikselach do obsługi przypadków brzegowych
        let nearBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - buffer;
        let currentSectionId = "";

        sections.forEach(section => {
            const sectionTop = section.offsetTop - buffer;
            const sectionHeight = section.offsetHeight;

            if (scrollPosition >= sectionTop && scrollPosition <= sectionTop + sectionHeight + buffer) {
                currentSectionId = section.getAttribute('id')!;
            }
        });

        if (nearBottom) {
            currentSectionId = sections[sections.length - 1].getAttribute('id')!; // Ustaw ostatnią sekcję jako aktywną, gdy blisko dołu
        }

        menuItems.forEach(item => {
            item.classList.remove('active');
            if (item.getAttribute('href') === '#' + currentSectionId) {
                item.classList.add('active');
            }
        });
    };

    window.addEventListener('scroll', checkSectionInView);

    const hamburger = document.querySelector('.hamburger')!;
    const menu = document.querySelector('.menu')!;

    hamburger.addEventListener('click', () => {
        menu.classList.toggle('active');
        setTimeout(checkSectionInView, 300); // Sprawdź aktywną sekcję po otwarciu menu
    });

    menuItems.forEach(item => {
        item.addEventListener('click', () => {
            if (menu.classList.contains('active')) {
                menu.classList.remove('active');
            }
        });
    });
});
